import dynamic from "next/dynamic";

import * as styles from "./Animation.module.scss";
import * as applyWith from "./Content/apply_with.json";
import * as autofill from "./Content/autofill.json";
import * as autofillBlue from "./Content/autofill_blue.json";
import * as findJobs from "./Content/find_jobs.json";
import * as generateAI from "./Content/generate_ai.json";
import * as getMatched from "./Content/get_matched.json";
import * as hero from "./Content/hero.json";
import * as internApplyWith from "./Content/intern_apply_with.json";
import * as internHero from "./Content/intern_hero.json";
import * as jobInsights from "./Content/job_insights.json";
import * as loadingMatches from "./Content/loadingMatches.json";
import * as matches from "./Content/matches.json";
import * as moonshot from "./Content/moonshot.json";
import * as onboarding from "./Content/onboarding.json";
import * as tailor from "./Content/tailor.json";
import * as tailorResume from "./Content/tailor_resume.json";
import * as track from "./Content/track.json";
import * as viewMore from "./Content/view_more.json";
import * as welcome1 from "./Content/welcome1.json";
import * as welcome2 from "./Content/welcome2.json";
import * as welcome3 from "./Content/welcome3.json";

const Lottie = dynamic(() => import("react-lottie-player"), { ssr: false });

interface Animation {
  animation:
    | "hero"
    | "find_jobs"
    | "apply_with"
    | "track"
    | "onboarding"
    | "matches"
    | "moonshot"
    | "intern_hero"
    | "intern_apply_with"
    | "get_matched"
    | "tailor"
    | "job_insights"
    | "autofill"
    | "autofill_blue"
    | "tailor_resume"
    | "welcome1"
    | "welcome2"
    | "welcome3"
    | "viewMore"
    | "loadingMatches"
    | "generate_ai";

  stop: boolean;
  faded?: boolean;
  center?: boolean;
  verticalCenter?: boolean;
}

export default function Animation({
  animation,
  stop,
  faded = false,
  center = false,
  verticalCenter = false,
}: Animation): JSX.Element {
  const animations = {
    hero,
    find_jobs: findJobs,
    apply_with: applyWith,
    track,
    onboarding,
    matches,
    moonshot,
    intern_hero: internHero,
    intern_apply_with: internApplyWith,
    get_matched: getMatched,
    tailor,
    job_insights: jobInsights,
    autofill,
    tailor_resume: tailorResume,
    welcome1,
    welcome2,
    welcome3,
    autofill_blue: autofillBlue,
    viewMore,
    loadingMatches,
    generate_ai: generateAI,
  };

  return (
    <div
      className={`pointer-events-none relative size-full ${styles.animation} ${faded ? styles.faded : ""} ${
        center ? "mx-auto" : ""
      } ${verticalCenter ? "flex items-center" : ""}`}
    >
      <Lottie
        loop
        animationData={animations[animation]}
        play={!stop}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid slice",
        }}
      />
    </div>
  );
}
