/* eslint-disable react/jsx-props-no-spreading */
import { DetailedHTMLProps, MetaHTMLAttributes } from "react";

import Head from "next/head";

export default function Meta(
  props: DetailedHTMLProps<MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>
): JSX.Element {
  return (
    <Head>
      <meta {...props} />
    </Head>
  );
}
