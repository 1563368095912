import Image from "next/image";
import Link from "next/link";

interface Logo {
  inverted?: boolean;
  externalLogo?: boolean;
  blueIcon?: boolean;
  goTo?: string;
}

export default function Logo({ externalLogo = false, inverted = false, blueIcon = false, goTo }: Logo): JSX.Element {
  const link = goTo || "/";
  let src = !inverted ? "/images/logos/logo.svg" : "/images/logos/logo_inverted.svg";
  if (inverted && blueIcon) src = "/images/logos/logo_inverted_blue.svg";

  if (externalLogo)
    return (
      <>
        <Link aria-label="Home" className="hidden h-8 sm:block" href={link}>
          <Image priority alt="Simplify Logo" height={32} src={src} width={146} />
        </Link>
        <Link aria-label="Home" className="flex h-8 items-center sm:hidden" href={link}>
          <Image priority alt="Simplify Logo" height={21.04} src={src} width={96} />
        </Link>
      </>
    );

  return (
    <Link aria-label="Home" className="flex h-8 w-[146px] shrink-0" href={link}>
      <Image priority alt="Simplify Logo" height={32} src={src} width={146} />
    </Link>
  );
}
