import Head from "next/head";

interface Title {
  title: string;
}

export default function Title({ title }: Title): JSX.Element {
  return (
    <Head>
      <title>{title}</title>
    </Head>
  );
}
